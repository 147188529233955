<!-- 发票管理-供应商发票 -->
<template>
  <div class="pagePadding">
    <div class="table">
      <!-- 供应商 -->
      <div class="item">
        <div class="title">供应商</div>
        <div class="body">
          <Select v-model="invoice.supplier_id" filterable class="width300">
            <Option v-for="item in cityList" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </div>
      </div>
      <!-- 发票单号 -->
      <!-- <div class="item">
        <div class="title">发票单号</div>
        <div class="body">
          {{ invoice.invoice_no }}
        </div>
      </div> -->
      <!-- 开票时间 -->
      <div class="item">
        <div class="title">开票时间</div>
        <div class="body">
          <DatePicker placeholder="请选择" format="yyyy-MM-dd" class="width300" :value="invoice.invoice_time" @on-change="changeTime"></DatePicker>
        </div>
      </div>
      <!-- 发票金额 -->
      <div class="item">
        <div class="title">发票金额</div>
        <div class="body">
          <!-- <Input :disabled='readonly' clearable placeholder="请输入" class="width300" v-model="invoice.invoice_amount" @input="changeNum" /> -->
          <Input class="width300" v-model="invoice.invoice_amount" @input="changeNum" />
          <span style="margin-left: 8px;">元</span>
        </div>
      </div>
      <!-- 发票 -->
      <div class="item borderBtm">
        <div class="title">发票</div>
        <div class="body">
          <div class="uploading width1000">
            <span v-for="(item, index) in debitNote" :key="index">
              <a @click="openFile(item.invoice_url)">{{ item.invoice_name }}</a>
              <i @click="deleteImage(index)" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="debitNote && debitNote.length == 0">只允许上传jpg,jpeg,png,pdf格式的文件</i>
            <FormUpload class="upload finger" label="上传" :multiple="true" @get-key="getKey(...arguments)"></FormUpload>
          </div>
        </div>
      </div>

      <p>供应商端关联单据</p>
      <!-- 关联采购单 -->
      <div class="newItem">
        <div class="title">关联采购单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in purchaseOrderList" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeletePur = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="purchaseOrderList && purchaseOrderList.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill">查询</span>
        </div>
      </div>
      <!-- 关联采购退货通知单 -->
      <div class="newItem borderBtm">
        <div class="title">关联采购退货通知单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in purchaseReturnList" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeletePurRe = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="purchaseReturnList && purchaseReturnList.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill1">查询</span>
        </div>
      </div>

      <p>经营端关联单据</p>
      <!-- 关联入库单 -->
      <div class="newItem borderBtm">
        <div class="title">关联入库单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in warehousingIdArry" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeletePurtr = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="warehousingIdArry && warehousingIdArry.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill2">查询</span>
        </div>
      </div>
      <!-- 关联采购退货单 -->
      <div class="newItem borderBtm">
        <div class="title">关联采购退货单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in ctWarehousingIdArry" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeletePurcg = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="ctWarehousingIdArry && ctWarehousingIdArry.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill3">查询</span>
        </div>
      </div>
    </div>
    <div class="pageBtm fr">
      <span class="pageBtn finger btnReset" @click="back">返回</span>
      <span class="pageBtn finger btnSure marginLeft20" v-if="!readonly" @click="beforeCommit">提交</span>
    </div>

    <!-- 确认提交-弹窗 -->
    <tips-component showModal="post" v-if="win" @cancleBtn="win = false" @sureBrn="commit"></tips-component>
    <!-- 确认删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeletePur[0]" @cancleBtn="isDeletePur = [false, undefined]" @sureBrn="deletePur(isDeletePur[1])"></tips-component>
    <!-- 确认删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeletePurRe[0]" @cancleBtn="isDeletePurRe = [false, undefined]" @sureBrn="deletePurRe(isDeletePurRe[1])"></tips-component>
    <!-- 确认删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeletePurtr[0]" @cancleBtn="isDeletePurtr = [false, undefined]" @sureBrn="deletePurtr(isDeletePurtr[1])"></tips-component>
    <!-- 确认删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeletePurcg[0]" @cancleBtn="isDeletePurcg = [false, undefined]" @sureBrn="deletePurcg(isDeletePurcg[1])"></tips-component>
    <!-- 关联采购单-弹窗 -->
    <Modal v-model="add" title="采购单列表" width="80">
      <Table v-if="add" :productList="addListColumns" :productData="addListData" :total="addTotal" :pages="addPages" totalText="条记录" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" @change-page="changePage" ref="customTable">
        <template slot-scope="{ row, index }" slot="product_list">
          <Poptip trigger="hover" :content="row.product_list">
            <p>{{ row.product_lists.join(',') }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add = false">取消</span>
      </div>
    </Modal>
    <!-- 关联采购退货通知单-弹窗 -->
    <Modal v-model="add1" title="关联采购退货通知单" width="80">
      <Table v-if="add1" :productList="addListColumns1" :productData="addListData1" :total="addTotal1" :pages="addPages1" totalText="条记录" @on-select="onSelect1" @on-select-cancel="onSelectionCancel1" @on-select-all="onSelectAll1" @on-select-all-cancel="onSelectionAllCancel1" @change-page="changePage1" ref="customTable1">
        <template slot-scope="{ row, index }" slot="product_list">
          <Poptip trigger="hover" :content="row.product_list">
            <p>{{ row.product_lists.join(',') }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm1">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add1 = false">取消</span>
      </div>
    </Modal>

    <!-- 关联入库单单-弹窗 -->
    <Modal v-model="add2" title="入库单列表" width="80" @on-cancel="cancel2">
      <Table v-if="add2" :productList="addListColumns2" :productData="addListData2" :total="addTotal2" :pages="addPages2" totalText="条记录" @on-select="onSelect2" @on-select-cancel="onSelectionCancel2" @on-select-all="onSelectAll2" @on-select-all-cancel="onSelectionAllCancel2" @change-page="changePage2" @on-selection-change="newOnSelect" ref="customTable2">
        <template slot-scope="{ row, index }" slot="product_name">
          <Poptip trigger="hover" :content="row.product_name.join(',')">
            <p>{{ row.product_name[0] }},{{ row.product_name[1] ? row.product_name[1] : '' }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm2">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="cancel2">取消</span>
      </div>
    </Modal>

    <!-- 关联入库单单子菜单-弹窗 -->
    <Modal v-model="WarehousingChildLoad" title="入库单产品列表" width="80" @on-cancel="WarehousingChildLoad = false">
      <Table v-if="WarehousingChildLoad" :productList="WarehousingChildColumns" :productData="WarehousingChildData" :total="WChildtotal" :pages="WChildPage" totalText="条记录" @on-select-cancel="WChildselectcancel" @on-select-all-cancel="WChildselectcancelall" @on-selection-change="WChildSelectChange" @change-page="changePageW" highlight-row :rowClass="rowClassName">
        <template slot-scope="{ row, index }" slot="product_name">
          <Poptip trigger="hover" :content="row.product_name.join(',')">
            <p>{{ row.product_name[0] }},{{ row.product_name[1] ? row.product_name[1] : '' }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="Wchild">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="Wchildcancel">取消</span>
      </div>
    </Modal>

    <!-- 关联采购退货单-弹窗 -->
    <Modal v-model="add3" title="出库单列表" width="80" @on-cancel="cancel3">
      <Table v-if="add3" :productList="addListColumns3" :productData="addListData3" :total="addTotal3" :pages="addPages3" totalText="条记录" @on-select="onSelect3" @on-select-cancel="onSelectionCancel3" @on-select-all="onSelectAll3" @on-select-all-cancel="onSelectionAllCancel3" @change-page="changePage3" @on-selection-change="newOnSelect2" ref="customTable3">
        <template slot-scope="{ row, index }" slot="product_name">
          <Poptip trigger="hover" :content="row.product_name.join(',')">
            <p>{{ row.product_name[0] }},{{ row.product_name[1] ? row.product_name[1] : '' }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm3">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="cancel3">取消</span>
      </div>
    </Modal>

    <!-- 关联采购退货单子菜单-弹窗 -->
    <Modal v-model="returnGoodsChildLoad" title="采购退货单产品列表" width="80" @on-cancel="returnGoodsChildLoad = false">
      <Table v-if="returnGoodsChildLoad" :productList="returnGoodsChildColumns" :productData="returnGoodsChildData" :total="returnGoodstotal" :pages="returnGoodsPage" totalText="条记录" @on-select-cancel="RChildselectcancel" @on-select-all-cancel="RChildselectcancelall" @on-selection-change="RChildSelectChange" @change-page="changePageR" highlight-row :rowClass="rowClassName"> </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="Rchild">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="Rchildcancel">取消</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// newZsupplierLnvoice
import FormUpload from '@/components/formUpload'
import tipsComponent from '@/components/tipsComponent'
import Table from '@/components/table'
export default {
  name: 'newZinvoice',
  components: {
    FormUpload,
    tipsComponent,
    Table,
  },
  data() {
    return {
      // 入库单loading
      WselectItem: [],
      // 暂存选中项
      weturnGoodsStorage: [],
      WarehousingChildID: [],
      WarehousingChildColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'quantity',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '小计',
          // key: 'amount',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '规格型号',
          key: 'model_name',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '账期',
          key: 'payment_days',
          minWidth: 80,
          align: 'center',
          render: (h,param) => {
            return h('div',{

            },param.row.payment_days + '天')
          }
        },
        {
          title: '交易类型',
          key: 'deal_type',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '单价',
          // key: 'unit_price',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.unit_price, true))
          },
        },
      ],
      WarehousingChildData: [],
      WarehousingChildLoad: false,
      WChildtotal: 0,
      WChildPage: {
        order_number: '',
        page: 1,
        rows: 10,
      },
      RselectItem: [],
      // 暂存选中项
      returnGoodsStorage: [],
      // 采购退货单
      // 获取退货单子产品id
      returnGoodsChildID: [],
      returnGoodsChildColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'quantity',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '小计',
          // key: 'amount',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '规格型号',
          key: 'model_name',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '单价',
          // key: 'unit_price',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.unit_price, true))
          },
        },
      ],
      returnGoodsChildData: [],
      returnGoodsChildLoad: false,
      returnGoodstotal: 50,
      returnGoodsPage: {
        order_number: '',
        page: 1,
        rows: 10,
      },
      cityList: [],
      add2: false,
      add3: false,
      addListData3: [],
      addTotal3: 0,
      addPages3: {
        supplier_id: '',
        page: 1,
        rows: 10,
      },
      selectedItem3: [],
      addListDataAll3: [],
      addListColumns3: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '出库单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          key: 'product_name',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '数量',
          key: 'total_number',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '出库时间',
          // key: 'create_time',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            if (this.$moment.unix(param.row.create_time).format('YYYY-MM-DD') !== '1970-01-01') {
              return h('span', this.$moment.unix(param.row.create_time).format('YYYY-MM-DD'))
            } else {
              return h('span', '')
            }
          },
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          render: (h, param) => {
            return h(
              'span',
              {
                style: {
                  // marginLeft: '30px',
                },
                class: 'tableFont',
                on: {
                  click: () => {
                    this.returnGoodsPage.page = 1
                    this.ReturnGoodInfo = param.row
                    this.getReturnGoodsChild(param.row)
                  },
                },
              },
              '详情'
            )
          },
        },
      ],
      addListColumns2: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '入库单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_name',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '数量',
          key: 'total_number',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '入库时间',
          // key: 'create_time',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            if (this.$moment.unix(param.row.create_time).format('YYYY-MM-DD') !== '1970-01-01') {
              return h('span', this.$moment.unix(param.row.create_time).format('YYYY-MM-DD'))
            } else {
              return h('span', '')
            }
          },
        },
        {
          title: '操作',
          width: 150,
          align: 'center',
          render: (h, param) => {
            return h(
              'span',
              {
                style: {
                  // marginLeft: '30px',
                },
                class: 'tableFont',
                on: {
                  click: () => {
                    this.WChildPage.page = 1
                    this.weturnGoodInfo = param.row
                    this.getWarehousingChild(param.row)
                  },
                },
              },
              '详情'
            )
          },
        },
      ],
      addListData2: [],
      addTotal2: 0,
      addPages2: {
        supplier_id: '',
        page: 1,
        rows: 10,
      },
      selectedItem2: [],
      addListDataAll2: [],
      isAll2: false,
      isAll3: false,
      /**
       * 采购退货单
       * */
      // 弹窗-非全选，false表是非全选状态
      isAll1: false,
      // 弹窗-暂存勾选项
      selectedItem1: [],
      // 弹窗-采购单列表
      addListData1: [],
      // 弹窗-表格全部数据
      addListDataAll1: [],
      // 弹窗-表头
      addListColumns1: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '订单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_list',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'total_number',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '下单时间',
          key: 'insert_time',
          minWidth: 100,
          align: 'center',
        },
      ],
      // 总条数
      addTotal1: 0,
      // 弹窗-页码
      addPages1: {
        supplier_id: '',
        purchase_type: '2',
        is_admin: -1,
        page: 1,
        rows: 10,
      },
      // 弹窗-关联采购退货单
      add1: false,
      // 回显 采购退货单通知单 选中项
      purchaseReturnList: [],

      /**
       * 采购单
       * */
      // 弹窗-非全选，false表是非全选状态
      isAll: false,
      // 弹窗-暂存勾选项
      selectedItem: [],
      // 弹窗-采购单列表
      addListData: [],
      // 弹窗-表格全部数据
      addListDataAll: [],
      // 弹窗-表头
      addListColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '订单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_list',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'total_number',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '下单时间',
          key: 'insert_time',
          minWidth: 100,
          align: 'center',
        },
      ],
      // 总条数
      addTotal: 0,
      // 弹窗-页码
      addPages: {
        supplier_id: '',
        purchase_type: '1',
        is_admin: -1,
        page: 1,
        rows: 10,
      },
      // 弹窗-关联采购单单
      add: false,
      // 回显 采购单 选中项
      purchaseOrderList: [],

      // 是否删除关联的采购退货单
      isDeletePurRe: [false, undefined],
      isDeletePurtr: [false, undefined],
      // 采购退货单
      isDeletePurcg: [false, undefined],
      // 是否删除关联的采购单
      isDeletePur: [false, undefined],
      // 发票金额是否符合条件
      isConform: true,
      // 是否提交
      win: false,
      // 限制日期
      limitDate: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now() - 0
        },
      },
      // 上传的发票
      debitNote: [],
      // 发票信息
      invoice: {
        supplier: '', // 供应商姓名
        supplier_id: '', // 供应商id
        invoice_no: '', // 发票单号
        invoice_time: '', // 开票日期
        invoice_amount: '', // 发票金额
        invoice_info: [], // 发票信息
        invoice_name: [],
        relate_order: [],
        purchase_id_array: [], // 采购单
        purchase_return_array: [], // 采购退货单通知单
        // usage_id_array: [], // 用量表
        warehousing_id_arry: [], // 入库单
        ct_warehousing_id_arry: [], // 采购退货单
      },
      // 用量表下拉
      // dosageList: [],
      // 入库单下拉
      inputOrderRecodeList: [],
      warehousingIdArry: [],
      ctWarehousingIdArry: [],
      // 是否只读
      readonly: false,
      ReturnGoodInfo: {},
      weturnGoodInfo: {},
    }
  },
  created() {
    this.getSupplierList()
    // // 发票单号
    // this.invoice.invoice_no = this.$route.query.invoice_no
    // // 0编辑 or 1详情
    // this.readonly = this.$route.query.readonly != '0'
    // 获取发票信息
    // this.getSupplierInvoiceDetail()
    // 入库单下拉列表
    // this.getInputOrderRecode()
  },
  watch: {},
  methods: {
    // 已关联成功的行标红
    rowClassName(row, index) {
      if (row.is_supplier_invoices == 1) {
        return 'bgredRow'
      }
      return ''
    },
    // 入库单子菜单翻页
    changePageW(e) {
      this.WChildPage.page = e
      this.getWarehousingChild(this.weturnGoodInfo)
    },
    // 采购退货子菜单翻页
    changePageR(e) {
      this.returnGoodsPage.page = e
      this.getReturnGoodsChild(this.ReturnGoodInfo)
    },
    // 入库子菜单取消勾选
    WChildselectcancel(list, row) {
      this.WselectItem = list
      // 取消引
      let ind = 0
      this.weturnGoodsStorage.forEach((item, index) => {
        if (row.id == item.id) {
          ind = index
        }
      })
      this.weturnGoodsStorage.splice(ind, 1)
    },
    // 入库子菜单取消全选
    WChildselectcancelall(list) {
      this.WselectItem = list
      // this.addListData2.forEach((item, index) => {
      //   if (item.order_number == this.WarehousingChildData[0].order_number) {
      //     this.$set(this.addListData2[index], '_checked', false)
      //   }
      // })
    },
    // 入库单取消
    Wchildcancel() {
      this.WarehousingChildLoad = false
      // this.WarehousingChildID = []
    },
    // 采购退货子菜单取消勾选
    RChildselectcancel(list, row) {
      this.RselectItem = list
      // 取消引
      let ind = 0
      this.returnGoodsStorage.forEach((item, index) => {
        if (row.id == item.id) {
          ind = index
        }
      })
      this.returnGoodsStorage.splice(ind, 1)
    },
    // 入库子菜单取消全选
    RChildselectcancelall(list) {
      this.RselectItem = list

      // this.addListData3.forEach((item, index) => {
      //   if (item.order_number == this.returnGoodsChildData[0].order_number) {
      //     this.$set(this.addListData3[index], '_checked', false)
      //   }
      // })
    },
    // 采购退货取消
    Rchildcancel() {
      this.returnGoodsChildLoad = false
      // this.returnGoodsChildID = []
    },
    // 入库单单选
    newOnSelect(list) {
      // if (list.length == 0) {
      //   this.WarehousingChildID = []
      //   return
      // }
      this.selectedItem2 = list
      list.forEach((row, index) => {
        this.WChildPage.order_number = row.order_number
        this.$http.get(this.$api.getWarehousingChild, this.WChildPage, false).then(res => {
          //  this.WarehousingChildID.push(...res.data)
          this.WChildSelectChange(res.data.rk_lines, 'push')
        })
      })
    },
    // 入库子订单发生变化
    WChildSelectChange(list, type) {
      // 去掉已关联的
      list = list.reduce((cur, next) => {
        if (next.is_supplier_invoices != 1) {
          cur.push(next)
        }
        return cur
      }, [])
      if (type) {
        this.WarehousingChildID.push(...list)
        let obj = {}
        this.WarehousingChildID = this.WarehousingChildID.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
        this.WselectItem = list
        this.weturnGoodsStorage.push(...list)
        let obj2 = {}
        this.weturnGoodsStorage = this.weturnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj2[next.id]) {
            cur.push(next)
            obj2[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      } else {
        this.WselectItem = list

        this.weturnGoodsStorage.push(...list)
        let obj = {}
        this.weturnGoodsStorage = this.weturnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      }
    },
    //  入库子订单
    Wchild() {
      // 如果子产品都勾选了那么勾选订单列表
      if (this.WselectItem.length == this.WarehousingChildData.length) {
        this.addListData2.forEach((item, index) => {
          if (item.order_number == this.WselectItem[0].order_number) {
            this.$set(this.addListData2[index], '_checked', true)
            this.selectedItem2.push(this.addListData2[index])
          }
        })
      } else {
        // 如果选择的长度
        this.addListData2.forEach((item, index) => {
          if (item.order_number == this.WarehousingChildData[0].order_number) {
            console.log('进来了')
            this.$nextTick(() => {
              this.$set(this.addListData2[index], '_checked', false)
              this.$forceUpdate()
            })
            for (let i = 0; i < this.selectedItem2.length; i++) {
              if (this.addListData2[index].order_number == this.selectedItem2[i].order_number) {
                this.selectedItem2.splice(i, 1)
                i--
              }
            }
          }
        })
      }
      if (this.WselectItem.length == 0) {
        this.WarehousingChildData.forEach((item, index) => {
          for (let i = 0; i < this.weturnGoodsStorage.length; i++) {
            if (item.id == this.weturnGoodsStorage[i].id) {
              this.weturnGoodsStorage.splice(i, 1)
              i--
            }
          }
        })
      }
      this.WarehousingChildID = JSON.parse(JSON.stringify(this.weturnGoodsStorage))
      this.WarehousingChildLoad = false
    },
    // 采购退货子菜单确认
    Rchild() {
      // 如果子产品都勾选了那么勾选订单列表
      if (this.RselectItem.length == this.returnGoodsChildData.length) {
        this.addListData3.forEach((item, index) => {
          if (item.order_number == this.RselectItem[0].order_number) {
            this.$set(this.addListData3[index], '_checked', true)
            this.selectedItem3.push(this.addListData3[index])
          }
        })
      } else {
        // 如果选择的长度
        this.addListData3.forEach((item, index) => {
          if (item.order_number == this.returnGoodsChildData[0].order_number) {
            console.log('进来了')
            this.$set(this.addListData3[index], '_checked', false)
            for (let i = 0; i < this.selectedItem3.length; i++) {
              if (this.addListData3[index].order_number == this.selectedItem3[i].order_number) {
                this.selectedItem3.splice(i, 1)
                i--
              }
            }
          }
        })
      }
      // 全部取消的情况下
      if (this.RselectItem.length == 0) {
        this.returnGoodsChildData.forEach((item, index) => {
          for (let i = 0; i < this.returnGoodsStorage.length; i++) {
            if (item.id == this.returnGoodsStorage[i].id) {
              this.returnGoodsStorage.splice(i, 1)
              i--
            }
          }
        })
      }
      this.addListData3.forEach((item1, index) => {
        // 遍历 暂存勾选项
        this.selectedItem3.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            this.$set(this.addListData3[index], '_checked', true)
          }
        })
      })
      this.returnGoodsChildID = JSON.parse(JSON.stringify(this.returnGoodsStorage))
      this.returnGoodsChildLoad = false
    },
    // 采购单选
    newOnSelect2(list) {
      // if (list.length == 0) {
      //   this.returnGoodsChildID = []
      //   return
      // }
      this.selectedItem3 = list
      list.forEach((row, index) => {
        this.returnGoodsPage.order_number = row.order_number
        this.$http.get(this.$api.getreturnGoodsChild, this.returnGoodsPage, false).then(res => {
          this.RChildSelectChange(res.data.ct_lines, 'push')
        })
      })
    },
    // 采购退货单子
    RChildSelectChange(list, type) {
      // 去掉已关联的
      list = list.reduce((cur, next) => {
        if (next.is_supplier_invoices != 1) {
          cur.push(next)
        }
        return cur
      }, [])
      if (type) {
        this.returnGoodsChildID.push(...list)
        let obj = {}
        this.returnGoodsChildID = this.returnGoodsChildID.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])

        this.RselectItem = list
        this.returnGoodsStorage.push(...list)
        let obj2 = {}
        this.returnGoodsStorage = this.returnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj2[next.id]) {
            cur.push(next)
            obj2[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      } else {
        this.RselectItem = list
        this.returnGoodsStorage.push(...list)
        let obj = {}
        this.returnGoodsStorage = this.returnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      }
    },
    // 获取采购退货单子列表
    getReturnGoodsChild(row) {
      this.returnGoodsChildLoad = true
      this.returnGoodsPage.order_number = row.order_number
      this.$http.get(this.$api.getreturnGoodsChild, this.returnGoodsPage, true).then(res => {
        this.returnGoodsChildData = res.data.ct_lines
        this.returnGoodstotal = res.data.total

        // 如果外层点了全选
        this.selectedItem3.forEach(item => {
          if (item.order_number == this.returnGoodsChildData[0].order_number) {
            this.returnGoodsChildData.forEach(item2 => {
              item2._checked = true
            })
          }
        })
        // 如果已经选择了就勾选上
        this.returnGoodsChildData.forEach((item, index) => {
          // 禁止选中
          if (item.is_supplier_invoices == '1') {
            this.$set(this.returnGoodsChildData[index], '_disabled', true)
          }
          this.returnGoodsChildID.forEach((item2, index2) => {
            if (item.id == item2.id) {
              item._checked = true
            }
          })
        })
      })
    },
    // 获取入库单子菜单
    getWarehousingChild(row) {
      this.WarehousingChildLoad = true
      this.WChildPage.order_number = row.order_number
      this.$http.get(this.$api.getWarehousingChild, this.WChildPage, true).then(res => {
        this.WarehousingChildData = res.data.rk_lines
        this.WChildtotal = res.data.total

        // 如果外部点了勾选里面也要全选上
        this.selectedItem2.forEach(item => {
          if (item.order_number == this.WarehousingChildData[0].order_number) {
            this.WarehousingChildData.forEach(item2 => {
              item2._checked = true
            })
          }
        })
        // 如果已经选择了就勾选上
        this.WarehousingChildData.forEach((item, index) => {
          // 禁止选中
          if (item.is_supplier_invoices == '1') {
            this.$set(this.WarehousingChildData[index], '_disabled', true)
          }
          this.WarehousingChildID.forEach((item2, index) => {
            if (item.id == item2.id) {
              item._checked = true
            }
          })
        })
      })
    },
    //  获取供应商名称
    getSupplierList() {
      this.$http.get(this.$api.supplierSelect, { supplier_name: '' }).then(res => {
        console.log(res, '数据')
        this.cityList = res.data
      })
    },
    addBill2() {
      if (!this.invoice.supplier_id) {
        this.$Message.warning('请选择供应商')
        return
      }
      // this.selectedItem2 = []
      // 采购单列表
      this.addPages2.supplier_id = this.invoice.supplier_id
      this.getAddBillAll2()
      this.add2 = true
      this.addPages2.rows = 10
      // this.addPages2.supplier_id = this.invoice.supplier_id
      this.getAddBill2()
    },
    // 关联采购退货单
    addBill3() {
      if (!this.invoice.supplier_id) {
        this.$Message.warning('请选择供应商')
        return
      }
      // this.selectedItem3 = []
      // 采购单列表
      this.addPages3.supplier_id = this.invoice.supplier_id
      this.getAddBillAll3()
      this.add3 = true
      this.addPages3.rows = 10
      // this.addPages2.supplier_id = this.invoice.supplier_id

      this.getAddBill3()
    },
    getAddBillAll3() {
      this.addPages3.rows = 1000
      this.$http.get(this.$api.getInvoiceReturngoods, this.addPages3).then(res => {
        this.addListDataAll3 = res.data.return_list
      })
    },
    getAddBillAll2() {
      this.addPages2.rows = 1000
      this.$http.get(this.$api.invoiceSupplierWarehouseReceipt, this.addPages2).then(res => {
        this.addListDataAll2 = res.data.return_list
      })
    },

    getAddBill2(turnPage = false) {
      this.$http.get(this.$api.invoiceSupplierWarehouseReceipt, this.addPages2, true).then(res => {
        this.addListData2 = res.data.return_list
        this.addTotal2 = res.data.total
        // 处理日期
        this.addListData2.forEach(item => {
          item.insert_time = item.insert_time ? this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho2(turnPage)
      })
    },
    // 关联采购退货单
    getAddBill3(turnPage = false) {
      this.$http.get(this.$api.getInvoiceReturngoods, this.addPages3, true).then(res => {
        this.addListData3 = res.data.result
        this.addTotal3 = res.data.total
        // 处理日期
        this.addListData3.forEach(item => {
          item.insert_time = item.insert_time ? this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        console.log(this.selectedItem3, '可以连接')
        this.selectedEcho3(turnPage)
      })
    },
    selectedEcho3(turnPage = false) {
      console.log(turnPage, 'turnPage')
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.purchaseReturnList
      if (!turnPage) {
        console.log(this.selectedItem3, '可以连接')
        this.selectedItem3 = [...this.selectedItem3]
      }
      console.log(this.addListData3, this.selectedItem3)
      // 去重
      // 存放唯一order_number作为去重的判断条件
      let obj = {}
      this.selectedItem3 = this.selectedItem3.reduce((cur, next) => {
        // order_number不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      console.log()
      // 遍历表格当前页
      this.addListData3.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem3.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll3 = this.selectedItem3.length == this.addTotal3
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    selectedEcho2(turnPage = false) {
      console.log(turnPage, 'turnPage')
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.purchaseReturnList
      if (!turnPage) {
        this.selectedItem2 = [...this.selectedItem2]
      }
      // 去重
      // 存放唯一order_number作为去重的判断条件
      let obj = {}
      this.selectedItem2 = this.selectedItem2.reduce((cur, next) => {
        // order_number不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData2.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem2.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })

      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll2 = this.selectedItem2.length == this.addTotal2
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    onSelect2(data, row) {
      this.selectedItem2.push(row)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll2 = this.selectedItem2.length == this.addTotal2
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    onSelect3(data, row) {
      this.selectedItem3.push(row)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll3 = this.selectedItem3.length == this.addTotal3
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    onSelectionCancel2(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem2.forEach((item, index) => {
        if (item.order_number === row.order_number) {
          foo = index
        }
      })
      // 并且取消内部已勾选项
      for (let i = 0; i < this.WarehousingChildID.length; i++) {
        if (this.WarehousingChildID[i].order_number == row.order_number) {
          this.WarehousingChildID.splice(i, 1)
          i--
        }
      }
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem2.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll2 = false
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    onSelectionCancel3(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem3.forEach((item, index) => {
        if (item.order_number === row.order_number) {
          foo = index
        }
      })
      // 并且取消内部已勾选项
      for (let i = 0; i < this.returnGoodsChildID.length; i++) {
        if (this.returnGoodsChildID[i].order_number == row.order_number) {
          this.returnGoodsChildID.splice(i, 1)
          i--
        }
      }
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem3.splice(foo, 1)

      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll3 = false
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    onSelectAll2(data) {
      console.log('ok')
      // 清空 暂存勾选项
      this.selectedItem2 = []
      // 赋值 暂存勾选项
      this.selectedItem2 = this.addListDataAll2
      // 添加勾选字段
      this.selectedItem2.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll2 = true
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    onSelectAll3(data) {
      // 清空 暂存勾选项
      this.selectedItem3 = []
      // 赋值 暂存勾选项
      this.selectedItem3 = this.addListDataAll3
      // 添加勾选字段
      this.selectedItem3.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll3 = true
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    onSelectionAllCancel2(data, row) {
      // 清空 暂存勾选项
      this.selectedItem2 = []
      this.isAll2 = false
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    onSelectionAllCancel3(data, row) {
      // 清空 暂存勾选项
      this.selectedItem3 = []
      this.isAll3 = false
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    changePage2(e) {
      this.addPages2.page = e
      this.getAddBill2(true)
    },
    changePage3(e) {
      this.addPages3.page = e
      this.getAddBill3(true)
    },
    confirm2() {
      // 获取选中的订单号
      let arr = []
      let obj = {}
      arr = this.WarehousingChildID.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      this.warehousingIdArry = arr
      this.invoice.warehousing_id_arry = []
      this.WarehousingChildID.forEach(item => {
        this.invoice.warehousing_id_arry.push(item.id)
      })
      this.calcAmount()
      this.add2 = false
    },
    confirm3() {
      // 获取选中的订单号
      let arr = []
      let obj = {}
      arr = this.returnGoodsChildID.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      this.ctWarehousingIdArry = arr
      this.invoice.ct_warehousing_id_arry = []
      this.returnGoodsChildID.forEach(item => {
        this.invoice.ct_warehousing_id_arry.push(item.id)
      })
      this.calcAmount()
      this.add3 = false
    },
    cancel2() {
      this.add2 = false
      this.addPages2.page = 1
    },
    cancel3() {
      this.add3 = false
      this.addPages3.page = 1
    },
    /**
     * 采购退货单
     * */
    // 弹窗-确定
    confirm1() {
      this.invoice.purchase_return_array = []
      this.purchaseReturnList = this.selectedItem1
      this.purchaseReturnList.forEach(item => {
        this.invoice.purchase_return_array.push(item.order_number)
      })
      // this.calcAmount()
      this.add1 = false
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel1(data) {
      // 清空 暂存勾选项
      this.selectedItem1 = []
      this.isAll1 = false
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-勾全选
    onSelectAll1(data) {
      // 清空 暂存勾选项
      this.selectedItem1 = []
      // 赋值 暂存勾选项
      this.selectedItem1 = this.addListDataAll1
      // 添加勾选字段
      this.selectedItem1.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll1 = true
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel1(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem1.forEach((item, index) => {
        if (item.order_number === row.order_number) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem1.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll1 = false
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-勾单选
    onSelect1(data, row) {
      this.selectedItem1.push(row)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll1 = this.selectedItem1.length == this.addTotal1
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-回显勾选项
    selectedEcho1(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.purchaseReturnList
      if (!turnPage) {
        this.selectedItem1 = [...this.purchaseReturnList, ...this.selectedItem1]
      }
      // 去重
      // 存放唯一order_number作为去重的判断条件
      let obj = {}
      this.selectedItem1 = this.selectedItem1.reduce((cur, next) => {
        // order_number不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData1.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem1.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll1 = this.selectedItem1.length == this.addTotal1
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-翻页
    changePage1(e) {
      this.addPages1.page = e
      this.getAddBill1(true)
    },
    // 弹窗-获取 采购单 数据
    getAddBill1(turnPage = false) {
      this.$http.get(this.$api.supplierPurchaseInvoice, this.addPages1, true).then(res => {
        this.addListData1 = res.data
        this.addTotal1 = res.total
        // 处理日期
        this.addListData1.forEach(item => {
          item.insert_time = item.insert_time ? this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          item.product_lists = item.product_list.split(',').splice(0, 2)
        })

        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho1(turnPage)
      })
    },
    // 弹窗-获取 采购单 全部数据
    getAddBillAll1() {
      this.addPages1.rows = 1000
      this.$http.get(this.$api.supplierPurchaseInvoice, this.addPages1).then(res => {
        this.addListDataAll1 = res.data
      })
    },
    // 弹窗-显示
    addBill1() {
      if (!this.invoice.supplier_id) {
        this.$Message.warning('请选择供应商')
        return
      }
      this.addPages1.supplier_id = this.invoice.supplier_id
      this.getAddBillAll1()
      this.selectedItem1 = []
      // 采购单列表
      this.add1 = true
      this.addPages1.rows = 10
      // this.addPages1.supplier_id = this.invoice.supplier_id

      this.getAddBill1()
    },
    // 弹窗-确定
    confirm() {
      this.purchaseOrderList = this.selectedItem
      this.purchaseOrderList.forEach(item => {
        this.invoice.purchase_id_array.push(item.order_number)
      })
      // this.calcAmount()
      this.add = false
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾全选
    onSelectAll(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      // 赋值 暂存勾选项
      this.selectedItem = this.addListDataAll
      // 添加勾选字段
      this.selectedItem.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = true
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem.forEach((item, index) => {
        if (item.order_number === row.order_number) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾单选
    onSelect(data, row) {
      this.selectedItem.push(row)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-回显勾选项
    selectedEcho(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.purchaseOrderList
      if (!turnPage) {
        this.selectedItem = [...this.purchaseOrderList, ...this.selectedItem]
      }

      // 存放唯一order_number作为去重的判断条件
      let obj = {}
      this.selectedItem = this.selectedItem.reduce((cur, next) => {
        // order_number不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-翻页
    changePage(e) {
      this.addPages.page = e
      this.getAddBill(true)
    },
    // 弹窗-获取 采购单 数据
    getAddBill(turnPage = false) {
      this.$http.get(this.$api.supplierPurchaseInvoice, this.addPages, true).then(res => {
        this.addListData = res.data
        this.addTotal = res.total
        // 处理日期
        this.addListData.forEach(item => {
          item.insert_time = item.insert_time ? this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          item.product_lists = item.product_list.split(',').splice(0, 2)
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho(turnPage)
      })
    },
    // 弹窗-获取 采购单 全部数据
    getAddBillAll() {
      this.addPages.rows = 1000
      this.$http.get(this.$api.supplierPurchaseInvoice, this.addPages).then(res => {
        this.addListDataAll = res.data
      })
    },
    // 弹窗-显示
    addBill() {
      if (!this.invoice.supplier_id) {
        this.$Message.warning('请选择供应商')
        return
      }
      this.selectedItem = []
      this.addPages.supplier_id = this.invoice.supplier_id
      // 采购单列表
      this.getAddBillAll()
      this.add = true
      this.addPages.rows = 10
      // this.addPages.supplier_id = this.invoice.supplier_id
      this.getAddBill()
    },

    // 提交前校验
    beforeCommit() {
      if (!this.invoice.invoice_time) {
        this.$Message.warning('开票时间不能为空')
        return
      }

      this.win = true
    },
    // 提交
    commit() {
      this.win = false
      this.getSupplierInvoiceDetail()
      // 整理发票信息
      if (this.debitNote.length != 0) {
        this.invoice.invoice_info = []
        this.debitNote.forEach(item => {
          this.invoice.invoice_info.push({
            invoice_name: item.invoice_name,
            invoice_key: item.invoice_key,
          })
        })
      } else {
        delete this.invoice.invoice_info
      }
      // 不存在就不传
      // 关联采购单
      if (this.invoice.purchase_id_array.length == 0) {
        console.log(this.invoice.purchase_id_array.length)
        delete this.invoice.purchase_id_array
      }
      // 关联采购退货通知单
      if (this.invoice.purchase_return_array.length == 0) {
        delete this.invoice.purchase_return_array
      }
      // 关联入库单
      if (this.invoice.warehousing_id_arry.length == 0) {
        delete this.invoice.warehousing_id_arry
      }
      // 采购退货单
      if (this.invoice.ct_warehousing_id_arry.length == 0) {
        delete this.invoice.ct_warehousing_id_arry
      }
      // delete this.invoice.supplier_id
      // 千分位转数值
      this.invoice.invoice_amount = String(this.invoice.invoice_amount.replace(/,/gi, ''))
      this.$http.post(this.$api.supplierInvoice, this.invoice).then(res => {
        if (res.status) {
          this.$Message.success('新增发票成功')
          this.$router.replace({
            path: '/invoiceManage',
          })
        }
      })
      sessionStorage.setItem('updataCache', '0')
    },

    // 计算金额
    calcAmount() {
      this.invoice.invoice_amount = 0
      this.WarehousingChildID.forEach(item => {
        this.invoice.invoice_amount += item.amount
      })
      this.returnGoodsChildID.forEach(item => {
        this.invoice.invoice_amount -= item.amount
      })
      this.invoice.invoice_amount = this.$utils.formatMoney(this.invoice.invoice_amount)
    },
    // 删除采购退货单
    deletePurcg(index) {
      this.invoice.ct_warehousing_id_arry = []
      // 删除出库子产品
      for (var i = 0; i < this.returnGoodsChildID.length; i++) {
        if (this.returnGoodsChildID[i].order_number == this.ctWarehousingIdArry[index].order_number) {
          this.returnGoodsChildID.splice(i, 1)
          i--
        }
      }
      // 删除暂存
      for (var i = 0; i < this.returnGoodsStorage.length; i++) {
        if (this.returnGoodsStorage[i].order_number == this.ctWarehousingIdArry[index].order_number) {
          this.returnGoodsStorage.splice(i, 1)
          i--
        }
      }
      // 删除勾选项
      for (let i = 0; i < this.selectedItem3.length; i++) {
        if (this.selectedItem3[i].order_number == this.ctWarehousingIdArry[index].order_number) {
          this.selectedItem3.splice(i, 1)
          i--
        }
      }
      this.ctWarehousingIdArry.splice(index, 1)
      this.returnGoodsChildID.forEach(item => {
        this.invoice.ct_warehousing_id_arry.push(item.id)
      })
      // this.invoice.ct_warehousing_id_arry.splice(index, 1)
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeletePurcg = [false, undefined]
    },
    // 删除入库单
    deletePurtr(index) {
      this.invoice.warehousing_id_arry = []
      // 删除出库子产品
      for (var i = 0; i < this.WarehousingChildID.length; i++) {
        if (this.WarehousingChildID[i].order_number == this.warehousingIdArry[index].order_number) {
          this.WarehousingChildID.splice(i, 1)
          i--
        }
      }
      // 删除暂存
      for (var i = 0; i < this.weturnGoodsStorage.length; i++) {
        if (this.weturnGoodsStorage[i].order_number == this.warehousingIdArry[index].order_number) {
          this.weturnGoodsStorage.splice(i, 1)
          i--
        }
      }

      // 删除勾选项
      for (let i = 0; i < this.selectedItem2.length; i++) {
        if (this.selectedItem2[i].order_number == this.warehousingIdArry[index].order_number) {
          this.selectedItem2.splice(i, 1)
          i--
        }
      }
      this.warehousingIdArry.splice(index, 1)
      this.WarehousingChildID.forEach(item => {
        this.invoice.warehousing_id_arry.push(item.id)
      })
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeletePurtr = [false, undefined]
    },
    // 删除采购退货通知单
    deletePurRe(index) {
      this.purchaseReturnList.splice(index, 1)
      this.invoice.purchase_return_array.splice(index, 1)
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeletePurRe = [false, undefined]
    },
    // 删除采购单
    deletePur(index) {
      this.purchaseOrderList.splice(index, 1)
      this.invoice.purchase_id_array.splice(index, 1)
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeletePur = [false, undefined]
    },
    // 删除图片
    deleteImage(index) {
      this.debitNote.splice(index, 1)
    },
    // 图片查看
    openFile(url) {
      window.open(url)
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url) {
      this.debitNote.push({
        invoice_key: key,
        invoice_url: url,
        invoice_name: file_name,
      })
    },
    // 数字修改限制
    changeNum(item) {
      let str = item + ''
      let minLength = 0
      let maxlength = 0
      if (str.indexOf('.') !== -1) {
        // 小数点前的长度
        minLength = str.split('.')[1].length
        // 小数点后的长度
        maxlength = str.split('.')[0].length
      } else {
        maxlength = str.length
      }
      if (item >= 0 && maxlength < 9 && minLength < 3) {
        this.invoice.invoice_amount = item
      } else {
        this.$Message.warning('请输入有效金额')
        this.$set(this.invoice, 'invoice_amount', null)
      }
    },
    // 改变金额

    // 选择日期
    changeTime(e) {
      this.invoice.invoice_time = e
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 获取发票信息
    getSupplierInvoiceDetail() {
      // // 处理 关联采购单
      // this.purchaseOrderList.forEach(item => {
      //   this.invoice.purchase_id_array.push(item.order_number)
      // })
      // // 处理 关联采购退货单
      // this.purchaseReturnList.forEach(item => {
      //   this.invoice.purchase_return_array.push(item.order_number)
      // })
      // this.$http.get(this.$api.supplierInvoiceDetail, { invoice_no: this.invoice.invoice_no }, true).then(res => {
      // this.invoice = res.data[0]
      // 关联采购退货单
      // this.invoice.warehousing_id_arry = this.warehousingIdArry
      delete this.invoice.usage_id_array
      // this.invoice.invoice_time = this.invoice.invoice_time ? this.$moment(this.invoice.invoice_time * 1000).format('YYYY-MM-DD') : ''
      // 处理金额
      if (!this.invoice.invoice_amount) {
        this.invoice.invoice_amount = 0
      }
      this.invoice.invoice_amount = this.$utils.formatMoney(this.invoice.invoice_amount)
      // 本地存储发票信息
      // this.debitNote = this.invoice.invoice_info || []
      // 处理 关联采购单
      // this.purchaseOrderList = JSON.parse(JSON.stringify(res.data[0].purchase_id_array))
      this.invoice.purchase_id_array = []
      if (this.purchaseOrderList == '') {
        this.purchaseOrderList = []
      }
      this.purchaseOrderList.forEach(item => {
        this.invoice.purchase_id_array.push(item.order_number)
      })
      // 处理 关联采购退货单
      // this.purchaseReturnList = JSON.parse(JSON.stringify(res.data[0].purchase_return_array))
      // this.invoice.purchase_return_array = []
      // if (this.purchaseReturnList == '') {
      //   this.purchaseReturnList = []
      // }
      // this.purchaseReturnList.forEach(item => {
      //   this.invoice.purchase_return_array.push(item.order_number)
      // })
      // })
      console.log(this.invoice, '456')
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  position: relative;

  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #525b6d;

    .newItem {
      display: flex;
      border: 1px solid #e8eaec;
      border-bottom: 0;
      .title {
        min-height: 50px;
        width: 200px;
        padding-left: 30px;
        background: #f8f8f9;
        border-right: 1px solid #e8eaec;
        display: flex;
        align-items: center;
      }
      .body {
        padding-left: 20px;
        display: flex;
        align-items: center;
        margin: 7px 0;
        .width1000 {
          width: 1000px;
        }
        .uploading {
          min-height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          padding-left: 7px;

          display: flex;
          flex-wrap: wrap;

          .tip {
            font-size: 14px;
            padding: 0 0 0 4px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .itemSpan {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin: 3px 10px 3px 0;
            // cursor: pointer;
            span {
              border: 0;
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            i {
              font-style: normal;
              padding-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .item {
      display: flex;
      height: 50px;
      line-height: 50px;
      border: 1px solid #e8eaec;
      border-bottom: 0;

      .title {
        width: 200px;
        padding-left: 30px;
        background-color: #f8f8f9;
        border-right: 1px solid #e8eaec;
      }

      .body {
        flex: 1;
        padding-left: 20px;
        display: flex;
        align-items: center;

        .uploading {
          height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          padding-left: 7px;
          .tip {
            font-size: 14px;
            padding: 0 0 0 4px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .upload {
            width: 52px;
            height: 100%;
            background-color: #f4f4f4;
            border-left: 1px solid #e3e3e3;
            float: right;
            text-align: center;
            font-size: 14px;
          }

          span {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin-right: 10px;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            i {
              font-style: normal;
              padding-left: 10px;
            }
          }
        }

        .width300 {
          width: 300px;
        }

        .width1000 {
          width: 1000px !important;
        }
      }
    }

    .borderBtm {
      border-bottom: 1px solid #e8eaec;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      color: #525b6d;
      margin: 20px 0;
    }
  }

  .pageBtm {
    position: absolute;
    right: 0;
    margin: 20px 20px 20px 0;
  }
}
</style>
